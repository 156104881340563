<script>
export default {
  name: 'SearchFormItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    required: Boolean,
  },
}
</script>

<template>
  <div class="form-item">
    <div class="form-item-label fs15" style="flex-shrink: 0">
      <span :class="{ required: required }"> {{ label }} </span>
    </div>
    <div style="width: 100%">
      <slot />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.form-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  color: #131120;

  &-label {
    font-weight: 400;

    .required {
      margin-left: 8px;
      display: flex;
      align-items: center;
      height: 100%;
      gap: 4px;

      &::before {
        content: '*';
        color: #ff0000;
      }
    }
  }
}

.form-item + .form-item {
  margin-top: 20px;
}
.v-input {
  ::v-deep .v-input__control {
    .v-input__slot {
      height: 32px;
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
