import { isEmpty, isMobile, isEmail, isIdCard } from '@prettyy/utils'

export function useValidation() {
  // 整数判断
  function integerValidator(value) {
    if (isEmpty(value)) {
      return true
    }

    if (Array.isArray(value)) {
      return value.every(val => /^-?[0-9]+$/.test(String(val)))
    }

    return /^-?[0-9]+$/.test(String(value)) || '必须是整数'
  }

  // 必填校验
  function required(value) {
    if (isEmpty(value)) return '这是必填项'

    return true
  }

  // 是否是手机号
  function isPhone(value) {
    if (isEmpty(value)) return true

    return isMobile(value) || '手机号码格式错误'
  }

  // 是否是邮箱
  function isMailbox(value) {
    if (isEmpty(value)) return true

    return isEmail(value) || '邮箱格式错误'
  }

  // 是否身份证
  function isIdCardNo(value) {
    if (isEmpty(value)) return true

    return isIdCard(value) || '身份证号码格式错误'
  }

  // 静止输入特殊字符
  function specialCharacter(value) {
    if (isEmpty(value)) return true

    return /^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(value) || '不能输入特殊字符'
  }

  // 禁止输入特殊字符

  return {
    integerValidator,
    required,
    isPhone,
    isMailbox,
    isIdCardNo,
    specialCharacter,
  }
}
