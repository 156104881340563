<script>
import { computed, onActivated, onBeforeMount, onDeactivated, onMounted, onUnmounted, provide, ref, getCurrentInstance, watch } from '@vue/composition-api'

export default {
  name: 'Grid',
  props: {
    cols: {
      type: [Number, Object],
      default: () => ({ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }),
    },
    collapsed: Boolean,
    collapsedRows: {
      type: Number,
      default: 1,
    },
    gap: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    // 注入响应断点
    const breakPoint = ref('xl')
    provide('breakPoint', breakPoint)

    // 监听屏幕变化
    function resize(e) {
      const width = (e.target).innerWidth
      // eslint-disable-next-line default-case
      switch (!!width) {
        case width < 768:
          breakPoint.value = 'xs'
          break
        case width >= 768 && width < 992:
          breakPoint.value = 'sm'
          break
        case width >= 992 && width < 1200:
          breakPoint.value = 'md'
          break
        case width >= 1200 && width < 1920:
          breakPoint.value = 'lg'
          break
        case width >= 1920:
          breakPoint.value = 'xl'
          break
      }
    }

    // 注入间隔
    provide('gap', Array.isArray(props.gap) ? props.gap[0] : props.gap)

    // 注入要开始折叠的index
    const hiddenIndex = ref(-1)
    provide('shouldHiddenIndex', hiddenIndex)

    // 注入 cols
    const gridCols = computed(() => {
      if (typeof props.cols === 'object') return props.cols[breakPoint.value] ?? props.cols

      return props.cols
    })
    provide('clos', gridCols)

    // 寻找需要折叠的字段 index

    const newThis = getCurrentInstance()?.proxy
    function findIndex() {
      const fields = []
      let suffix = null
      const slots = (newThis.$slots.default ?? [])
      slots.forEach(slot => {
        // suffix
        if (typeof slot.componentOptions === 'object' && slot.componentOptions.tag === 'GridItem' && slot.componentOptions.propsData?.suffix !== undefined) suffix = slot
        // slot children
        if (typeof slot.componentOptions === 'object' && Array.isArray(slot.componentOptions.children)) fields.push(...slot.componentOptions.children)
      })

      // 计算占用比例

      let suffixCols = 0
      if (suffix) {
        suffixCols = ((suffix).componentOptions.propsData[breakPoint.value]?.span ?? (suffix).componentOptions.propsData?.span ?? 1)
        + ((suffix).componentOptions.propsData[breakPoint.value]?.offset ?? (suffix).componentOptions.propsData?.offset ?? 0)
      }

      try {
        let find = false
        fields.reduce((prev = 0, current, index) => {
          // eslint-disable-next-line no-param-reassign
          prev
            += ((current).componentOptions.propsData[breakPoint.value]?.span ?? (current).componentOptions.propsData?.span ?? 1)
            + ((current).componentOptions.propsData[breakPoint.value]?.offset ?? (current).componentOptions.propsData?.offset ?? 0)
          if (Number(prev) > props.collapsedRows * gridCols.value - suffixCols) {
            hiddenIndex.value = index
            find = true
            // eslint-disable-next-line no-throw-literal
            throw 'find it'
          }

          return prev
        }, 0)
        if (!find) hiddenIndex.value = -1
      } catch (e) {
        console.log(e)
      }
    }

    onBeforeMount(() => props.collapsed && findIndex())
    onMounted(() => {
      resize({ target: { innerWidth: window.innerWidth } })
      window.addEventListener('resize', resize)
    })
    onActivated(() => {
      resize({ target: { innerWidth: window.innerWidth } })
      window.addEventListener('resize', resize)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resize)
    })
    onDeactivated(() => {
      window.removeEventListener('resize', resize)
    })

    // 断点变化时 执行 findIndex
    watch(
      () => breakPoint.value,
      () => {
        if (props.collapsed) findIndex()
      },
    )

    // 监听 collapsed
    watch(
      () => props.collapsed,
      value => {
        if (value) return findIndex()
        hiddenIndex.value = -1
      },
    )

    // 设置间距
    const gridGap = computed(() => {
      if (typeof props.gap === 'number') return `${props.gap}px`
      if (Array.isArray(props.gap)) return `${props.gap[1]}px ${props.gap[0]}px`

      return 'unset'
    })

    // 设置 style
    const style = computed(() => ({
      display: 'grid',
      gridGap: gridGap.value,
      gridTemplateColumns: `repeat(${gridCols.value}, minmax(0, 1fr))`,
    }))

    return {
      style,
      gridCols,
      breakPoint,
    }
  },
}
</script>

<template>
  <div :style="style">
    <slot />
  </div>
</template>

<style scoped>

</style>
