import { isObject } from '@prettyy/utils'
import dayjs from 'dayjs'

// 移除Object第一层的空数据
export function removeObjectEmpty(obj) {
  if (!isObject(obj)) return obj

  return Object.keys(obj).filter(key => (obj[key] !== null && obj[key] !== undefined)).reduce((arr, key) => ({ ...arr, [key]: obj[key] }), {})
}

export const kFormatter = (num, fraction = 2) => (num > 999 ? `${(num / 1000).toFixed(fraction)}k` : num)

// 金额格式化
export function numberFormat(value) {
  let unit = ''
  let newValue = value
  const k = 10000
  const sizes = ['', '万', '亿', '万亿']
  let i
  if (value > k) {
    i = Math.floor(Math.log(value) / Math.log(k))
    // eslint-disable-next-line no-restricted-properties
    newValue = ((value / Math.pow(k, i))).toFixed(1)
    unit = sizes[i]
  }

  return newValue + unit
}

export function fileConversion(size) {
  if (size === 0 || size === '0') return '0 B'
  const k = 1024 // or 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(size) / Math.log(k))
  // eslint-disable-next-line no-restricted-properties,newline-before-return
  return `${(size / Math.pow(k, i)).toFixed(2)} ${sizes[i]}`
}

// 时间格式转化
export function dateFormat(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return dayjs(date).format(format)
}
