<script>
import TabsNav from './TabsNav.vue'

// 引入tab导航页组件
export default {
  name: 'TabsPro',
  components: { TabsNav },
  props: {
    // 绑定的activeName的值
    value: null, // 接收到的值即为当前高亮的是哪一项
    // 传递一个函数，作为tab切换的钩子函数
    beforeLeave: {
      // 切换标签之前的钩子，若返回 false 或者返回 Promise 且被 reject，则阻止切换
      type: Function,
      default: () => true, // 默认为true，始终允许切换tab
    },
    type: {
      type: String,
      default: 'card', // 默认卡片样式
    },

    // 是否显示tabs头部
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tabItemArr: [], // 用于传递给tabNav组件信息数据的数组
      activeName: this.value, // 高亮的是哪个tab标签页选项卡
    }
  },
  mounted() {
    /**
     * 计算收集tab页内容信息，将需要用到的信息存在tabItemArr数组中
     * 并传递给tabNav组件，tabNav组件根据tabItemArr信息去v-for渲染有哪些
     * */
    this.calcTabItemInstances()
  },
  methods: {
    calcTabItemInstances() {
      // 重点方法
      // 获取使用的地方的my-tab标签中间的内容
      if (this.$slots.default) {
        const slotTabItemArr = this.$slots.default
        this.tabItemArr = slotTabItemArr.map(item => item.componentInstance)
        if (!this.activeName && this.tabItemArr.length > 0) {
          this.setCurrentName(this.tabItemArr[0].name)
        }
      } else {
        this.tabItemArr = [] // 没传递就置为空，当然需要规范使用组件，规范传递相关参数
      }
    },
    handleTabClick(tabItem) {
      this.$emit('tabClick', tabItem)
      const newTabName = tabItem.name
      this.setCurrentName(newTabName)
    },

    // 考虑到可能有异步操作，所以加上async await（比如在切换tab标签页之前，做一个问询）
    async setCurrentName(newTabName) {
      const oldTabName = this.activeName
      const res = await this.beforeLeave(newTabName, oldTabName)
      if (res) {
        this.$emit('input', newTabName)
        this.activeName = newTabName
      }
    },
  },
  render() {
    // 准备参数，以便把参数传递给tab-nav组件
    const navData = {
      props: {
        tabItemArr: this.tabItemArr, // 内容区相关信息数组
        activeName: this.activeName, // 当前高亮的是哪一项
        onTabClick: this.handleTabClick, // 点击某一tab项的回调
        type: this.type, // tab类型
      },
    }

    return (
      <div class="tab-Box">
        {this.showHeader ? <tabs-nav {...navData}></tabs-nav> : '' }
        <div class="tab-content-item-box">{this.$slots.default}</div>
      </div>
    )
  },
}
</script>
