<script>
import { computed, ref, useSlots } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { isFunction } from '@prettyy/utils'
import empty from './image/empty.png'

export default {
  name: 'SuperTable',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '-',
    },
    emptyText: {
      type: String,
      default: '没有更多数据了',
    },
    borderShow: {
      type: Boolean,
      default: true,
    },
    rowConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    // 过滤隐藏数据
    const tableColumns = computed(() => props.columns.filter(item => !item.hidden))
    const { isDark } = useAppConfig()
    const slots = useSlots()
    const slotsKeys = Object.keys(slots)
    const tableRef = ref()
    const configRow = computed(() => {
      const data = { isCurrent: false, isHover: true }
      if (typeof props.rowConfig === 'object') {
        return Object.assign(data, props.rowConfig)
      }

      return data
    })

    // 过滤方法
    function format({ cellValue }, headDetails) {
      // 存在格式化方法直接返回格式化处理方法
      if (isFunction(headDetails.format)) {
        return headDetails.format(cellValue)
      }

      // 存在枚举获取枚举
      if (headDetails.enum && Array.isArray(headDetails.enum)) {
        const item = headDetails.enum.find(val => val.value === cellValue)

        return item ? (item.label || item.text) : props.placeholder
      }

      // 不存在枚举直接返回
      return (cellValue !== undefined && cellValue !== null) ? cellValue : props.placeholder
    }

    // 选中变化触发
    function selectChange({ checked, records, reserves, row }) {
      let selectionRows = []
      if (checked) {
        // 第一次选数据，还未进行翻页时
        if (reserves.length === 0) {
          selectionRows = records
        } else {
          // 数据集合，翻页存在已选中的数据时,拼接新选中的数据
          selectionRows = [...reserves, ...records]
        }
      } else {
        // 取消选中时
        selectionRows.filter(item => item.id !== row.id)
      }
      emit('selection-change', selectionRows)
    }

    // 全选处理
    function selectAllChange({ checked, records, reserves }) {
      let selectionRows = []

      // 全选中时
      if (checked) {
        // 第一次选数据，还未进行翻页时
        if (reserves.length === 0) {
          selectionRows = records
        } else {
          selectionRows = [...reserves, ...records]
        }
      } else {
        selectionRows = reserves
      }

      emit('selection-change', selectionRows)
    }

    function setCheckboxRow(array) {
      tableRef.value.setCheckboxRow(array, true)
    }

    // 清空选中
    function clearCheckboxRow() {
      tableRef.value.clearCheckboxRow()
      emit('selection-change', [])
    }

    function reloadData() {
      tableRef.value.reloadData(props.data)
    }

    return {
      tableColumns,
      slotsKeys,
      isDark,
      format,
      tableRef,
      configRow,
      empty,
      selectChange,
      clearCheckboxRow,
      setCheckboxRow,
      selectAllChange,
      reloadData,
    }
  },
}
</script>

<template>
  <vxe-table
    ref="tableRef"
    :class="[borderShow ? 'border-show' : '']"
    :data="data"
    :loading-config="{icon: 'vxe-icon-indicator roll', text: '加载中...'}"
    :row-config="configRow"
    height="auto"
    show-overflow
    v-bind="$attrs"
    @checkbox-all="selectAllChange"
    @checkbox-change="selectChange"
    v-on="$listeners"
  >
    <template v-for="item in tableColumns">
      <vxe-column
        :key="item.value"
        :field="item.props || item.value"
        :title="item.label || item.text"
        :align="item.align || 'left'"
        v-bind="item"
        :formatter="e => format(e, item)"
      >
        <template v-if="!item.type && slotsKeys.includes(item.props || item.value)" #default="{ row, rowIndex }">
          <slot :column="item" :index="rowIndex" :name="item.props || item.value" :row="row" />
        </template>
      </vxe-column>
    </template>
    <template v-if="$slots.empty" #empty>
      <slot name="empty" />
    </template>
    <template v-else #empty>
      <div class="flex-vertical-center gap18">
        <img :src="empty" alt="" style="max-width: 125px;" />
        <div>{{ emptyText }}</div>
      </div>
    </template>
  </vxe-table>
</template>
<style lang='scss' scoped>
::v-deep .vxe-table--header-wrapper {
  background: #FAFAFA;
}

.border-show {
  ::v-deep .vxe-table--border-line {
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    z-index: 5 !important;
  }
}

</style>

<style lang="scss" scoped>
/*滚动条整体部分*/
.darkScrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.darkScrollbar ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}
/*滚动条里面的小方块，能向上向下移动*/
.darkScrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
.darkScrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(168, 168, 168, 0.52);
}
.darkScrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.darkScrollbar ::-webkit-scrollbar-corner {
  background-color: #FFFFFF;
}
</style>
