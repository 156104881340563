import http from '@http'

// 获取人工评估列表
export function getEvaluateList(data) {
  return http.post('/back/right-protect/manager/man/page', data)
}

// 获取版权保护列表
export function getProtectList(data) {
  return http.post('/back/right-protect/manager/protect/page', data)
}

// 获取维权列表
export function getRightList(data) {
  return http.post('/back/right-protect/manager/lost/page', data)
}

// 导出数据
export function exportData(data) {
  return http.downloadPostGet('/back/right-protect/manager/excel', data)
}
