<script>
import AppView from '@/components/AppView.vue'
import { useValidation } from '@/hooks/useValidation'
import { reactive, ref, nextTick } from '@vue/composition-api'
import { asynchronousReturn } from '@core/utils'
import { skitEntry } from '@/views/short-play/service'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'ShortPlay',
  components: { AppView },
  setup() {
    const { required } = useValidation()
    const formRef = ref()
    const { message } = useMessage()
    const formData = reactive({
      skitName: undefined,
      source: 'web',
    })

    function submit() {
      asynchronousReturn(formRef.value.validate).then(() => {
        skitEntry({ ...formData }).then(({ status, message: messageText }) => {
          if (status === 200) {
            message.success('新增短剧成功!')
            formData.skitName = undefined
            nextTick(() => {
              formRef.value.resetValidation()
            })
          } else {
            message.error(messageText)
          }
        }).catch(error => {
          message.error(error)
        })
      })
    }

    return {
      required,
      formData,
      formRef,
      submit,
    }
  },
}
</script>

<template>
  <app-view>
    <div>
      <vl-form ref="formRef" label-width="100" @submit.native.prevent>
        <vl-form-item label="短剧名称" required>
          <div class="d-flex gap12">
            <div style="width: 300px">
              <v-text-field
                v-model="formData.skitName"
                v-trim
                maxlength="20"
                :rules="[required]"
                placeholder="请输入"
                dense
                outlined
                @keyup.native.enter="{}"
              />
            </div>

            <v-btn color="primary" @click="submit">
              加入短剧库
            </v-btn>
          </div>
        </vl-form-item>
      </vl-form>
    </div>
  </app-view>
</template>

<style scoped lang="scss">

</style>
