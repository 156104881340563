import { computed, reactive, toRefs } from '@vue/composition-api'
import { removeObjectEmpty } from '@/utils'
import dayjs from 'dayjs'
import { isFunction } from '@prettyy/utils'
import { useMessage } from '@/hooks/useMessage'
import { RequestHttp } from '@http'

export function useTable({
  api,
  initParam = {},
  mapEnum = {},
  dataCallBack,
  requestError,
  requestAfterCallBack = () => {},
  requestCallback,
  multipleInit = () => {},
}) {
  const { message } = useMessage()
  const state = reactive({
    tableData: [],
    selected: [],
    loading: false, // 是否加载完毕
    page: {
      pageIndex: 1,
      pageSize: 10,
      total: 0,
    },
  })

  /**
   * 分页数据
   * @type {WritableComputedRef<{size: number, page: number}>}
   */
  const pageParam = computed({
    get: () => ({ page: state.page.pageIndex, size: state.page.pageSize }),
    set: val => console.log('我是分页更新之后的值', val),
  })

  /**
   * 更新分页数据
   * @param page
   */
  function updatePageable(page = {}) {
    Object.assign(state.page, page)
  }

  /**
   * 初始化
   * @returns {Promise<boolean>}
   */
  async function init() {
    state.tableData = []
    if (!api) return false
    try {
      let params = removeObjectEmpty({ ...pageParam.value, ...((initParam.value ? initParam.value : initParam)) })
      if (requestCallback) {
        params = requestCallback(params)
      }

      try {
        state.loading = true
        let request = api
        if (typeof api === 'object') {
          request = api.value
        }
        let { data } = await request(params)

        // 接口数据没有返回200直接抛出异常
        if (data.status !== 200) {
          throw new Error(data.message)
        }
        if (dataCallBack) {
          data = dataCallBack(data)
        }

        state.tableData = data.data.records || []
        updatePageable({ total: data.data.total || 0 })
        requestAfterCallBack(state.tableData)
        const time = setTimeout(() => {
          state.loading = false
          clearTimeout(time)
        }, 200)
      } catch (error) {
        state.loading = false
        message.error(error.message)
      }
    } catch (e) {
      return requestError && requestError(e)
    }

    // 初始化清空选中
    if (isFunction(multipleInit())) {
      multipleInit()
    }

    return true
  }

  // 查询
  async function search() {
    state.tableData = []
    state.selected = []
    state.page.pageIndex = 1
    init()
  }

  /**
   * 条数变化
   * @param val
   */
  function handleSizeChange(val) {
    state.page.pageIndex = 1
    state.page.pageSize = val
    init()
  }

  /**
   * 页数变化
   * @param val
   */
  function handleCurrentChange(val) {
    state.page.pageIndex = val
    init()
  }

  /**
   * 获取label
   * @param key
   * @param val
   * @returns {*|string}
   */
  function filterMap(key, val) {
    const arr = mapEnum[key] || []
    const data = arr.find(item => item.value === val)

    return (data && data.text) || ''
  }

  /**
   * 格式化时间
   * @param time
   * @param formats
   * @returns {string}
   */
  function format(time, formats = 'YYYY-MM-DD HH:mm:ss') {
    if (!time) return ''

    return dayjs(time).format(formats)
  }

  return {
    ...toRefs(state),
    init,
    filterMap,
    format,
    search,
    handleSizeChange,
    handleCurrentChange,
  }
}
