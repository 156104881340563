<script>
import { ref, reactive, computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import TabsPro from '@/components/tabs/TabsPro.vue'
import SuperTable from '@/components/SuperTable.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import AppView from '@/components/AppView.vue'
import SearchForm from '@/components/SearchForm/index.vue'
import { useTable } from '@/hooks/useTable'
import {
  getEvaluateList,
  getProtectList,
  getRightList,
  exportData,
} from '@/views/information-gathering/service'
import { useMessage } from '@/hooks/useMessage'

export default {
  name: 'InformationList',
  components: {
    SearchForm,
    AppView,
    TabPanePro,
    SuperTable,
    TabsPro,
  },
  setup() {
    const tabList = [
      { label: '人工预估', name: 'labour' },
      { label: '版权保护', name: 'copyright' },
      { label: '维权', name: 'safeguard' },
    ]
    const activeName = ref('labour')
    const searchForm = reactive({
      time: [],
      corpName: undefined,
      contactor: undefined,
      telphone: undefined,
    })
    const { message, confirm } = useMessage()

    const now = dayjs()
    const startOf31DaysAgo = now.subtract(30, 'days').format('YYYY-MM-DD')
    const endOfToday = now.endOf('day').format('YYYY-MM-DD')
    searchForm.time = [startOf31DaysAgo, endOfToday]
    const searchConfig = ref([
      { el: 'DatePickers', props: 'time', label: '提交日期', range: true, rangeSeparator: '至' },
      { el: 'VTextField', props: 'corpName', label: '公司名称', maxlength: 50 },
      { el: 'VTextField', props: 'contactor', label: '联系人', maxlength: 50 },
      { el: 'VTextField', props: 'telphone', label: '联系电话', maxlength: 15 },
    ])
    const tableColumns = [
      { props: 'corpName', label: '公司名称', width: 250 },
      { props: 'contactor', label: '联系人', width: 250 },
      { props: 'telphone', label: '联系电话', width: 200 },
      { props: 'createdAt', label: '提交日期', width: 200, format: value => dayjs(value).format('YYYY-MM-DD') },
      { props: 'worksName', label: '剧目' },
    ]
    const apis = {
      labour: getEvaluateList,
      copyright: getProtectList,
      safeguard: getRightList,
    }

    const { init, search, page, handleSizeChange, handleCurrentChange, tableData, loading } = useTable({
      api: computed(() => apis[activeName.value]),
      initParam: searchForm,
      dataCallBack: data => ({
        data: {
          records: data.data.list,
          total: data.data.total,
        },
      }),
      requestCallback: params => ({
        ...params,
        startDate: params.time[0],
        endDate: params.time[1],
        time: undefined,
      }),
    })

    init()

    function reset() {
      const keys = Object.keys(searchForm)
      keys.forEach(key => {
        if (key === 'time') {
          searchForm[key] = [startOf31DaysAgo, endOfToday]
        } else searchForm[key] = undefined
      })

      search()
    }

    function tabChange(e) {
      activeName.value = e.name
      search()
    }

    // 导出数据
    function fileExport() {
      confirm('确定导出页面数据？', '温馨提示', { type: 'warning' }).then(() => {
        const typeMap = {
          labour: 1,
          copyright: 2,
          safeguard: 3,
        }
        const data = {
          ...searchForm,
          startDate: searchForm.time[0],
          endDate: searchForm.time[1],
          time: undefined,
          type: typeMap[activeName.value],
        }
        const name = tabList.find(item => item.name === activeName.value)?.label
        exportData(data).then(response => {
          if (response.status !== 200) return message.error('导出失败！')
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
          const url = window.URL.createObjectURL(blob) // 创建一个临时的url指向blob对象
          const a = document.createElement('a')
          a.href = url
          a.download = `${name}清单`
          a.click()
          window.URL.revokeObjectURL(url)
        })
      })
    }

    return {
      tabList,
      activeName,
      tabChange,
      searchForm,
      searchConfig,
      tableColumns,
      tableData,
      page,
      loading,
      handleSizeChange,
      handleCurrentChange,
      search,
      fileExport,
      reset,
    }
  },
}
</script>

<template>
  <app-view>
    <template #header>
      <div
        class="flex-vertical"
        style="width: 100%"
      >
        <TabsPro v-model="activeName" @tabClick="tabChange">
          <TabPanePro
            v-for="item in tabList"
            :key="item.name"
            :label="item.label"
            :name="item.name"
          />
        </TabsPro>
        <SearchForm
          :columns="searchConfig"
          :value="searchForm"
          :search="search"
          :reset="reset"
        />
      </div>
    </template>

    <template #main-header>
      <v-btn color="primary" @click="fileExport">
        导出
      </v-btn>
    </template>

    <SuperTable ref="tableRef" :columns="tableColumns" :data="tableData" :loading="loading" />

    <template #footer>
      <vl-pagination
        :page-size="page.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :page-index="page.pageIndex"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </app-view>
</template>

<style scoped lang="scss">
::v-deep .tab-content-item-box {
  .tab-pane-pro {
    padding: 0;
  }
}
</style>
