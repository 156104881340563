<script>
export default {
  name: 'TabsNav',
  props: {
    tabItemArr: {
      type: Array,
      default: () => ([]),
    },
    activeName: {
      type: String,
      default: '',
    },
    onTabClick: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    function changeActiveName(tabItem) {
      // 自己点自己就不让执行了
      if (tabItem.name === props.activeName) {
        return
      }

      // 如果包含禁用项disabled属性（即处于禁用状态），也不让执行（搭配.isForbiddenItem类名）
      if (tabItem.disabled) {
        return
      }
      this.onTabClick(tabItem)
    }

    return {
      changeActiveName,
    }
  },
}
</script>

<template>
  <div class="tab-nav-item-box" :class="[type === 'succinct' ? 'succinct' : '']">
    <div
      v-for="(tabItem, index) in tabItemArr"
      :key="index"
      :class="[
        'tab-nav-item',
        tabItem.name === activeName ? 'highLight' : '',
        tabItem.disabled ? 'isForbiddenItem' : '',
        type === 'succinct' ? 'succinct-item' : ''
      ]"
      @click="changeActiveName(tabItem)"
    >
      {{ tabItem.label }}
    </div>
  </div>
</template>

<style lang='scss' scoped>
.tab-nav-item-box {
  width: 100%;
  border-bottom: thin solid rgba(94, 86, 105, 0.14);

  .tab-nav-item {
    display: inline-block;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    font-weight: 500;
    padding: 0 12px;
    cursor: pointer;
    background-color: #F6F7F8;
    transition: all 0.3s;
    border: 1px solid #E8E9EC;
    border-bottom: 0;

    // 非禁用时
    &:not(.isForbiddenItem):hover {
      color: #9155fd;
    }

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    &:last-child {
      border-radius: 0 6px 0 0;
    }
  }
  .tab-nav-item + .tab-nav-item {
    border-left: 0;
  }

  // 高亮项样式
  .highLight {
    color: #9155fd;
    background-color: #FFFFFF;
  }

  // 禁用样式
  .isForbiddenItem {
    cursor: not-allowed;
    color: #aaa;
  }
}

.succinct {
  &-item {
    position: relative;
    transition: all .2s linear;
    background: #ffffff !important;
    border: none !important;

    &::after {
      transition: all 0.2s linear;
      transform: translateX(-50%) translateY(50%) scaleX(0);
      content: '';
      width: 100%;
      position: absolute;
      left: 50%;
      bottom: 0;
      border-bottom: 2px solid var(--v-primary-base);
      border-radius: 4px;
    }

    &.highLight {
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(50%) scaleX(1);
        bottom: 0;
        border-bottom: 2px solid var(--v-primary-base);
      }
    }
  }
}
</style>
