<script>
import { computed, ref, inject, watch } from '@vue/composition-api'

export default {
  name: 'GridItem',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    span: {
      type: Number,
      default: 1,
    },
    suffix: Boolean,
  },
  setup(props, { attrs }) {
    const isShow = ref(true)
    const breakPoint = inject('breakPoint', ref('xl'))
    const shouldHiddenIndex = inject('shouldHiddenIndex', ref(-1))
    watch(
      () => [shouldHiddenIndex.value, breakPoint.value],
      n => {
        if (attrs.index) isShow.value = !(n[0] !== -1 && Number.parseInt(attrs.index, 10) >= Number(n[0]))
      },
      {
        immediate: true,
      },
    )

    const gap = inject('gap', 0)
    const cols = inject('clos', ref(4))
    const style = computed(() => {
      const span = props[breakPoint.value]?.span ?? props.span
      const offset = props[breakPoint.value]?.offset ?? props.offset
      if (props.suffix) {
        return {
          gridColumnStart: cols.value - span - offset + 1,
          gridColumnEnd: `span ${span + offset}`,
          marginLeft: offset !== 0 ? `calc(((100% + ${gap}px) / ${span + offset}) * ${offset})` : 'unset',
        }
      }

      return {
        gridColumn: `span ${span + offset > cols.value ? cols.value : span + offset}/span ${
          span + offset > cols.value ? cols.value : span + offset
        }`,
        marginLeft: offset !== 0 ? `calc(((100% + ${gap}px) / ${span + offset}) * ${offset})` : 'unset',
      }
    })

    return {
      isShow,
      cols,
      style,
    }
  },
}
</script>

<template>
  <div v-show="isShow" :style="style">
    <slot />
  </div>
</template>

<style scoped>

</style>
